<template>
  <pvp-modal
    :value="value"
    width="960"
    @input="$emit('input', false)"
  >
    <div slot="modal-title">{{ $t('teams.edit_title') }}</div>
    <loader v-if="isLoading" />
    <edit-team-form v-else-if="isEditFormVisible" :hash="hash" />
    <InfoBox v-else variant="warning">
      {{ $t('teams.edit_isRegistred') }}
    </InfoBox>
  </pvp-modal>
</template>

<script>
import EditTeamForm from '@components/TeamComponents/EditTeamForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import { fetchTeam } from '@src/shared/api/team';

export default {
  name: 'EditTeamModal',
  components: { EditTeamForm, InfoBox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isEditFormVisible: false,
    };
  },
  watch: {
    value: {
      handler(isOpen) {
        if (isOpen) {
          this.fetchTeamData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchTeamData() {
      this.isLoading = true;

      fetchTeam(this.hash)
        .then((data) => {
          this.isEditFormVisible =
            data?.canEditProfile || data?.canEditRoster;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
