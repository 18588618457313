<template>
  <main-layout :is-loading="loadingState.page">
    <div class="background-overlay"></div>
    <div v-if="team" class="team-page full-height">
      <div class="pvp-container">
        <div class="team-headline">
          <headline class="centered">
            <h1 slot="title">{{ $t('teams.single') }}</h1>
          </headline>
          <BaseSocialLinks
            class="team-socials"
            :show-title="false"
            :share-title="shareTitle"
            :share-img="team.image"
          />
        </div>
        <div class="team-header">
          <div class="header-column avatar">
            <edit-logo
              type="team"
              :can-edit="team.canEditAvatar"
              :hash="team.hash"
              @updated="updateTeamInfo"
            >
              <team-avatar :src="team.imgLogo"></team-avatar>
            </edit-logo>
            <div class="team-nick">{{ team.name }}</div>
          </div>
          <div class="header-column info">
            <div class="team-nick">{{ team.name }}</div>
          </div>
          <template v-if="showStatistic">
            <div class="header-column stats matches">
              <div class="stats-title">
                {{
                  $tc(
                    'matches.played',
                    team.teamDetails.matchesStatistic
                      .totalMatchesCount,
                  )
                }}
              </div>
              <div class="stats-body">
                <div class="matches-stats-list">
                  <div class="stats-list-item wins">
                    <div class="key">{{ $t('teams.wins') }}</div>
                    <div class="value">
                      {{
                        team.teamDetails.matchesStatistic
                          .wonMatchesCount
                      }}
                      ({{
                        team.teamDetails.matchesStatistic
                          .wonMatchesPercentage
                      }}%)
                    </div>
                  </div>
                  <div class="stats-list-item loses">
                    <div class="key">{{ $t('teams.defeats') }}</div>
                    <div class="value">
                      {{
                        team.teamDetails.matchesStatistic
                          .loseMatchesCount
                      }}
                      ({{
                        team.teamDetails.matchesStatistic
                          .loseMatchesPercentage
                      }}%)
                    </div>
                  </div>
                  <div class="stats-list-item withdraw">
                    <div class="key">{{ $t('teams.draw') }}</div>
                    <div class="value">
                      {{
                        team.teamDetails.matchesStatistic
                          .drawMatchesCount
                      }}
                    </div>
                  </div>
                </div>
                <div class="matches-stats-bar">
                  <div
                    class="filler-bar"
                    :style="{
                      width: `${team.teamDetails.matchesStatistic.wonMatchesPercentage}%`,
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="header-column stats tournaments">
              <div class="stats-title">
                {{ $t('teams.lastTournaments') }}:
              </div>
              <div class="stats-body">
                <div class="tournaments-list">
                  <div
                    v-for="tournament in team.lastTournamentsStatistic"
                    :key="tournament.id"
                    class="tournaments-list-item"
                  >
                    <BaseLink
                      :to="{
                        name: 'tournament',
                        params: {
                          id: tournament.urlCode || tournament.id,
                        },
                      }"
                      :title="tournament.name"
                      class="tournament-link"
                      >{{ tournament.name }}
                    </BaseLink>
                    <span v-if="tournament.place > 0" class="place"
                      ><b>{{ tournament.place }}</b>
                      {{ $t('places.single') }}</span
                    >
                    <span v-else class="place">{{
                      tournament.status.text
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="header-column stats placeholder">
              <div class="placeholder-icon">
                <icon name="pvp-swords" />
              </div>
              <template v-if="isUserTeamProfile">
                <div class="placeholder-text">
                  {{ $t('teams.statsPlaceholder_current') }}!
                </div>
                <div class="placeholder-action">
                  <pvp-btn
                    variant="secondary"
                    :to="{ name: 'tournaments' }"
                  >
                    {{ $t('teams.toTournaments') }}
                  </pvp-btn>
                </div>
              </template>
              <template v-else>
                <div class="placeholder-text">
                  {{ $t('teams.statsPlaceholder_other') }}
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="team-info">
          <div class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_game') }}
            </div>
            <div class="team-info-value">
              <game-tag :id="team.idGame" />
            </div>
          </div>
          <div class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_membersCount') }}
            </div>
            <div class="team-info-value">{{ team.numMembers }}</div>
          </div>
          <div class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_country') }}
            </div>
            <div class="team-info-value">
              <country-tag :id="team.idCountry" />
            </div>
          </div>
          <div v-if="team.status" class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_status') }}
            </div>
            <div class="team-info-value team-status">
              <template v-if="team.status === 'formed'">
                <Icon
                  name="check"
                  class="team-icon-status formed"
                  inline
                />
              </template>
              <template v-if="team.status === 'created'">
                <Icon
                  name="clock"
                  class="team-icon-status created"
                  inline
                />
              </template>
              <template v-if="team.status === 'deleted'">
                <Icon
                  name="cross"
                  class="team-icon-status deleted"
                  inline
                />
              </template>
              {{ $t(`teams.${team.status}`) }}
            </div>
          </div>
          <div v-if="websiteLink" class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_website') }}
            </div>
            <a
              :href="websiteLink"
              target="_blank"
              class="team-info-value team-website"
              >{{ team.website }}</a
            >
          </div>
          <div v-if="teamMembers" class="team-info-item">
            <div class="team-info-key">
              {{ $t('teams.info_emptySlots') }}
            </div>
            <div class="team-info-value">{{ emptySlots }}</div>
          </div>
        </div>
        <div v-if="!!teamMembers" class="team-members">
          <headline class="members-headline">
            <h2 slot="title">{{ $t('teams.members') }}</h2>
            <div slot="addon" class="addon members-quantity">
              {{ teamMembersQuantity }}
            </div>
            <div slot="addon" class="addon">
              <pvp-btn
                v-if="canEdit"
                size="sm"
                icon-left="settings"
                variant="secondary"
                @click="openEditModal"
                >{{ $t('global.edit') }}
              </pvp-btn>
            </div>
          </headline>
          <div class="members-container">
            <div
              v-for="user in teamMembers"
              :key="user.hash"
              class="member"
            >
              <BaseUserLinkWithoutStore
                :display-name="true"
                :image-size="memberImageSize"
                :data="user"
              >
                <pvp-tooltip slot="username">
                  <span class="wrap">{{ user.tnNick }}</span>
                  <template slot="tooltip">{{
                    $t('players.nickname_site')
                  }}</template>
                </pvp-tooltip>
                <pvp-tooltip slot="gamenick" class="gamenick">
                  <game-tag :id="team.idGame" :display-name="false" />
                  {{ user.gameNick }}
                  <template slot="tooltip">{{
                    $t('players.nickname_game_label')
                  }}</template>
                </pvp-tooltip>
                <span slot="userrole" class="wrap">{{
                  membersRoles[user.role]
                }}</span>
              </BaseUserLinkWithoutStore>
              <div v-if="user.role === 1" class="captain-label">
                <icon name="star" />
              </div>
            </div>
          </div>
        </div>
        <tabs-menu :list="tabs" class="team-tabs" />

        <section class="page-content-section">
          <router-view :hash="hash" />
        </section>
      </div>
    </div>
    <template v-if="!!team && canEdit">
      <edit-team-modal v-model="isOpenEditModal" :hash="hash" />
    </template>

    <team-invite-modal
      v-if="team && invitationHash"
      v-model="invitationModalOpened"
      :hash="hash"
      :invitation-hash="invitationHash"
    />
  </main-layout>
</template>

<script>
import EditTeamModal from '@components/TeamComponents/EditTeamModal.vue';
import TeamInviteModal from '@components/TeamComponents/TeamInviteModal.vue';
import EditLogo from '@components/BaseComponents/EditLogo.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import { fetchTeam, fetchTeamDetails } from '@src/shared/api/team';

export default {
  name: 'Team',
  components: {
    EditLogo,
    EditTeamModal,
    TeamInviteModal,
    Icon,
    BaseSocialLinks,
    BaseUserLinkWithoutStore,
    BaseLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
    invitationHash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tabs: [],
      membersRoles: {
        1: this.$t('teams.memberRoles_captain'),
        2: this.$t('teams.memberRoles_player'),
        3: this.$t('teams.memberRoles_spare'),
      },
      isOpenEditModal: false,

      invitationModalOpened: false,
      team: null,
    };
  },
  page() {
    return {
      title: this.teamTitle,
    };
  },
  computed: {
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('application', ['appIsSmall']),
    ...mapGetters('profile', ['isLoggedIn', 'getCurrentProfileHash']),

    teamTitle() {
      const teamTitle = this.team ? this.team.name : null;
      return `${this.$t('teams.linkTooltip', { name: teamTitle })}`;
    },

    teamMembers() {
      return [...this.team.players.main, ...this.team.players.spare];
    },

    spareMembers() {
      return this.team.players.spare;
    },

    mainMembersCount() {
      return this.teamMembers.length - this.spareMembers.length;
    },

    isUserTeamProfile() {
      const teams = this.getUserTeams(this.getCurrentProfileHash);

      if (!teams) {
        return false;
      }

      return teams.map((team) => team.hash).includes(this.hash);
    },

    currentUserIsMember() {
      return (
        this.teamMembers &&
        this.teamMembers.find(
          (member) => member.hash === this.getCurrentProfileHash,
        )
      );
    },

    websiteLink() {
      const link = _.get(this.team, 'website', null);

      if (!link) {
        return null;
      }

      return link.replace(/^(http(s?):\/\/)?(.*)$/g, 'https://$3');
    },

    teamMembersQuantity() {
      return this.teamMembers !== undefined
        ? this.teamMembers.length
        : 0;
    },

    emptySlots() {
      const text = [];

      const playersDeclension = (num, spare = false) => {
        if (spare) {
          return this.$tc('teams.players_spare', num);
        }

        return this.$tc('teams.players_main', num);
      };

      const mainSlotsLeft =
        this.team.numMembers - this.mainMembersCount;
      const spareSlotsLeft =
        this.team.numSpares - this.spareMembers.length;

      if (mainSlotsLeft) {
        text.push(`${playersDeclension(mainSlotsLeft)}`);
      }

      if (spareSlotsLeft) {
        text.push(`${playersDeclension(spareSlotsLeft, true)}`);
      }

      return text.length
        ? text.join(', ')
        : this.$t('teams.slots_none');
    },

    memberImageSize() {
      return this.appIsSmall ? 60 : 90;
    },

    showStatistic() {
      return (
        !!this.team.teamDetails &&
        (!!this.team.teamDetails.matchesStatistic ||
          !!this.team.teamDetails.lastTournamentsStatistic)
      );
    },

    canEdit() {
      return (
        this.team.canEditProfile ||
        (!this.team.canEditProfile && this.team.canEditRoster)
      );
    },

    shareTitle() {
      return this.currentUserIsMember
        ? this.$t('teams.share_current')
        : this.$t('teams.share_other', {
            host: window.location.host.toLocaleUpperCase(),
          });
    },
  },
  watch: {
    hash: {
      handler: 'onCreated',
      immediate: true,
    },
  },
  created() {
    if (this.invitationHash) {
      this.invitationModalOpened = true;
    }
  },
  methods: {
    ...mapActions('users', ['fetchUserProfile']),
    redirectToTeamPage() {
      this.$router
        .replace({
          name: 'team',
          params: { hash: this.hash },
        })
        .catch(() => ({}));
    },

    async onCreated() {
      this.loadingState.page = true;

      const tabs = [
        {
          title: this.$t('teams.tabs_ratings'),
        },
        {
          title: this.$t('teams.tabs_tournaments'),
          link: 'tournaments',
        },
        {
          title: this.$t('teams.tabs_match'),
          link: 'match',
        },
        {
          title: this.$t('teams.tabs_changes'),
          link: 'changes',
        },
      ];

      this.tabs = tabs.map((tab) => ({
        title: tab.title,
        link: {
          name: tab.link ? `team-${tab.link}` : 'team',
          params: {
            hash: this.hash,
          },
        },
      }));

      if (this.isLoggedIn) {
        this.fetchUserProfile({
          hash: this.getCurrentProfileHash,
          complex: true,
        });
      }

      const [team, teamDetails] = await Promise.all([
        fetchTeam(this.hash),
        fetchTeamDetails(this.hash),
      ]).finally(() => {
        this.loadingState.page = false;
      });

      this.team = {
        ...team,
        ...team.team,
        teamDetails,
      };
    },

    openEditModal() {
      this.isOpenEditModal = true;
    },

    updateTeamInfo(data) {
      this.team = {
        ...this.team,
        image: data?.fileUrl,
        imgLogo: data?.fileUrl,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pvp-container {
  @include max-tablet() {
    padding-left: 0;
    padding-right: 0;
  }
}

.background-overlay {
  position: absolute;
  z-index: -1;
  height: 600px;
  width: 100%;
}

.team-page {
  padding-top: $headerHeight + 30px;
  @include max-tablet() {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.team-header {
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px 8px 0 0;
  background: #2d2d30;
  margin-top: 26px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.11); */
  display: flex;
  /* background-color: $dark-two; */
  padding: 30px;

  .header-column {
    &.avatar {
      position: relative;

      &:hover {
        .team-avatar-edit {
          background-color: rgba(#000, 0.7);
          color: rgba($dodger-blue, 1);
        }
      }

      .team-avatar-edit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($dodger-blue, 0);
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  @include max-laptop() {
    margin-top: 12px;
    flex-direction: column;
    padding: 16px 12px;

    .header-column {
      width: 100%;

      &.avatar {
        display: flex;
        align-items: center;

        &:hover {
          .team-avatar-edit {
            width: 60px;
            height: 60px;
          }
        }

        .team-avatar {
          width: 60px;
          height: 60px;
        }

        .team-nick {
          display: block;
          width: 100%;
          margin-left: 12px;
          word-break: break-all;
          font-size: 24px;
          font-weight: bold;
        }
      }

      &.info {
        margin-left: 0;

        .team-nick {
          display: none;
        }
      }

      &.stats {
        &.placeholder {
          width: 100%;
          flex: 0 0 100%;
          margin-left: 0;
          border-left: none;

          .placeholder-icon {
            display: none;
          }

          .placeholder-text {
            margin-top: 0;
          }
        }

        &.matches {
          margin-left: 0;
        }

        &.tournaments {
          border-left: none;
          padding-left: 0;
          margin-left: 0;
        }

        .stats-title {
          height: auto;
        }

        .stats-body {
          margin-top: 6px;
        }
      }
    }

    .header-column + .header-column:not(.info) {
      border-top: 1px solid rgba(white, 0.1);
      padding-top: 14px;
      margin-top: 16px;
    }
  }
}

.header-column {
  &.avatar {
    align-self: center;

    .team-avatar {
      width: 114px;
      height: 114px;
    }

    .team-nick {
      display: none;
    }
  }

  &.info {
    margin-left: 30px;

    .team-nick {
      width: 100%;
      height: 40px;
      word-break: break-all;
      font-size: 32px;
      font-weight: bold;
    }
  }

  &.stats {
    &.placeholder {
      width: 50%;
      margin-left: auto;
      border-left: 1px solid rgba(white, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .placeholder-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }

      .placeholder-text {
        text-align: center;
        margin-top: 12px;
      }

      .placeholder-action {
        margin-top: 12px;
      }
    }

    &.matches {
      margin-left: auto;

      .matches-stats-list {
        display: flex;

        .stats-list-item {
          .key {
            font-size: 13px;
            color: rgba(white, 0.5);
          }

          .value {
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
          }

          &.wins {
            .value {
              color: $dark-pastel-green;
            }
          }

          &.loses {
            .value {
              color: $orangey-red-two;
            }
          }

          &.withdraw {
            .value {
              color: rgba(white, 0.5);
            }
          }
        }

        .stats-list-item + .stats-list-item {
          margin-left: 20px;
        }
      }

      .matches-stats-bar {
        margin-top: 12px;
        height: 6px;
        background-color: $orangey-red;
        border-radius: 3px;

        .filler-bar {
          height: 100%;
          width: 70%;
          background-color: $dark-pastel-green;
          border-radius: 3px;
        }
      }
    }

    &.tournaments {
      border-left: 1px solid rgba(white, 0.1);
      padding-left: 26px;
      margin-left: 26px;

      .tournaments-list {
        .tournaments-list-item {
          font-size: 13px;
          height: 24px;
          display: flex;
          align-items: flex-start;

          .tournament-link {
            max-width: 230px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .place {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: lowercase;
            max-width: 250px;
            @include max-laptop() {
              max-width: 100%;
            }

            &:before {
              content: '—';
              margin-left: 6px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .stats-title {
      font-size: 16px;
    }

    .stats-body {
      margin-top: 16px;
    }
  }
}

.team-info {
  padding: 16px 34px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 2px;
  background-color: rgba($dark-two, 0.6);
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 0 0 8px 8px;
  @include max-laptop() {
    padding: 16px 12px;
    flex-direction: column;
  }
}

.team-info-key,
.team-info-value {
  font-size: 13px;
  color: $white;
  line-height: normal;
}

.team-info-item {
  display: flex;
  flex-direction: column;
  @include max-laptop() {
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.team-info-key {
  margin-bottom: 10px;
  @include max-laptop() {
    margin-bottom: 0;
    margin-right: 10px;
    opacity: 0.5;
  }
}

.team-info-value {
  &.team-status {
    display: flex;
    align-items: center;

    .team-icon-status {
      margin-right: 6px;
      font-size: 12px;

      &.formed {
        color: $azure;
      }

      &.created {
        color: $gold;
      }

      &.deleted {
        color: $orangey-red;
      }
    }
  }

  &.team-website {
    max-width: 200px;
    word-break: break-all;
  }
}

.team-members {
  margin-top: 50px;

  .members-headline {
    @include max-tablet() {
      align-items: center;
      flex-direction: row;
      .headline-addon {
        margin-top: 0;
      }
      .members-quantity {
        margin-left: 8px;
      }
    }
  }
}

.members-quantity {
  opacity: 0.6;
  font-size: 24px;
  font-weight: bold;

  @include max-tablet() {
    font-size: 18px;
  }
}

.members-container {
  margin-top: 20px;
  padding: 10px 25px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background-color: rgba($dark-two, 0.41);
  @include max-laptop() {
    padding: 16px 12px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.member {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  @include min-laptop() {
    width: 33%;
  }
  @include tablet-only() {
    width: 50%;
  }
  @include max-tablet() {
    width: 100%;
  }

  .wrap {
    white-space: normal;
    word-wrap: break-word;
  }

  .tt-wrapper,
  .user-link {
    max-width: 100%;
  }

  .captain-label {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      314.38deg,
      #ef8120 5.79%,
      #ffcf53 111.49%
    );
    border-color: $dark-two;
    border-style: solid;
    position: absolute;
    border-radius: 50%;
    @include min-laptop() {
      height: 28px;
      width: 28px;
      bottom: 4px;
      left: 64px;
      border-width: 4px;
      .icon {
        width: 14px;
        height: 14px;
      }
    }

    @include max-laptop() {
      width: 20px;
      height: 20px;
      bottom: 2px;
      left: 42px;
      border-width: 2px;
      .icon {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.team-tabs {
  margin-top: 30px;
}

.page-content-section {
  margin-top: 30px;
}

.team-headline {
  display: flex;
  justify-content: space-between;
  @include max-laptop() {
    flex-direction: column;
    .team-socials {
      order: 1;
      margin-bottom: 20px;
    }
    .centered {
      order: 2;
    }
  }
  @include max-tablet() {
    margin-left: 12px;
    margin-right: 12px;
  }
}
</style>
