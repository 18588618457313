var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pvp-table adaptive"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row"},[_c('cell-header',[_vm._v("№")]),_c('cell-header',{attrs:{"t":"teams.edit_members_userNickname"}}),_c('cell-header',[_vm._v(_vm._s(_vm.gameNickTitle))]),_c('cell-header',{attrs:{"t":"teams.info_country"}}),_c('cell-header',{attrs:{"t":"teams.edit_members_role"}}),_c('cell-header')],1)]),_c('div',{staticClass:"table-body"},[_vm._l((_vm.membersList),function(members,key){return [(members.list.length)?_c('div',{key:`${key}-title}`,staticClass:"table-title"},[_vm._v(" "+_vm._s(members.listTitle)+" ")]):_vm._e(),_vm._l((members.list),function(user,index){return _c('div',{key:`${key}-${index}`,staticClass:"table-row"},[_c('cell-simple',{attrs:{"value":index + 1}}),(user)?[_c('BaseUserLinkWithoutStore',{staticClass:"userlink",attrs:{"data":user}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t('teams.edit_members_userNickname')))])],2),_c('cell-simple',{staticClass:"gamenick"},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.gameNickTitle))]),_c('div',{staticClass:"gamenick-title"},[_vm._v(_vm._s(user.gameNick))])],2),_c('cell-simple',{attrs:{"t":"teams.info_country"}},[_c('country-tag',{attrs:{"id":user.idCountry}})],1),_c('cell-simple',{staticClass:"role",attrs:{"t":"teams.edit_members_role"}},[(user.role === 1)?_c('Icon',{attrs:{"name":"crown","inline":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.membersRoles[user.role])+" ")],1),_c('div',{staticClass:"table-column dropdown"},[(user.role !== 1)?_c('pvp-btn',{staticClass:"dropdown-btn",attrs:{"variant":"clear","icon-left":"dots"},on:{"click":function($event){return _vm.toggleMemberControls(user.hash)}}}):_vm._e(),(_vm.activeUserHash === user.hash)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                () => _vm.toggleMemberControls(user.hash)
              ),expression:"\n                () => toggleMemberControls(user.hash)\n              "}],staticClass:"members-control-popup"},[_c('div',{staticClass:"members-control",on:{"click":function($event){return _vm.changeRole(user.hash)}}},[_c('icon',{attrs:{"name":members.isSpare ? 'arrow-up' : 'arrow-down'}}),_vm._v(" "+_vm._s(_vm.$t( `teams.edit_members_${ members.isSpare ? 'toMain' : 'toSpare' }`, ))+" ")],1),(
                  user.role !== 1 &&
                  !_vm.editOnlyMembers &&
                  !members.isSpare
                )?_c('div',{staticClass:"members-control",on:{"click":function($event){return _vm.changeCaptain(user.hash)}}},[_c('Icon',{attrs:{"name":"crown","inline":""}}),_vm._v(" "+_vm._s(_vm.$t('teams.edit_members_changeCaptain'))+" ")],1):_vm._e(),_c('div',{staticClass:"members-control",on:{"click":function($event){return _vm.deleteMember(user.hash)}}},[_c('Icon',{attrs:{"name":"cross","inline":""}}),_vm._v(" "+_vm._s(_vm.$t('teams.edit_members_delete'))+" ")],1)]):_vm._e()],1)]:[_c('cell-simple',{staticClass:"empty",attrs:{"value":_vm.$t('teams.edit_members_emptySlot')}}),_c('cell-simple',{staticClass:"empty"},[_vm._v("—")]),_c('cell-simple',{staticClass:"empty"},[_vm._v("—")]),_c('cell-simple',{staticClass:"empty"},[_vm._v("—")]),_c('cell-simple',{staticClass:"empty"})]],2)})]})],2),_c('member-errors-modal',{attrs:{"errors":_vm.changeRoleErrors},model:{value:(_vm.isErrorModalOpen),callback:function ($$v) {_vm.isErrorModalOpen=$$v},expression:"isErrorModalOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }