<!-- eslint-disable no-console -->
<template>
  <div class="pvp-table adaptive">
    <div class="table-header">
      <div class="table-row">
        <cell-header>№</cell-header>
        <cell-header t="teams.edit_members_userNickname" />
        <cell-header>{{ gameNickTitle }}</cell-header>
        <cell-header t="teams.info_country" />
        <cell-header t="teams.edit_members_role" />
        <cell-header />
      </div>
    </div>

    <div class="table-body">
      <template v-for="(members, key) in membersList">
        <div
          v-if="members.list.length"
          :key="`${key}-title}`"
          class="table-title"
        >
          {{ members.listTitle }}
        </div>
        <div
          v-for="(user, index) in members.list"
          :key="`${key}-${index}`"
          class="table-row"
        >
          <cell-simple :value="index + 1" />
          <template v-if="user">
            <BaseUserLinkWithoutStore :data="user" class="userlink">
              <template slot="title">{{
                $t('teams.edit_members_userNickname')
              }}</template>
            </BaseUserLinkWithoutStore>
            <cell-simple class="gamenick">
              <template slot="title">{{ gameNickTitle }}</template>
              <div class="gamenick-title">{{ user.gameNick }}</div>
            </cell-simple>
            <cell-simple t="teams.info_country">
              <country-tag :id="user.idCountry" />
            </cell-simple>
            <cell-simple t="teams.edit_members_role" class="role">
              <Icon v-if="user.role === 1" name="crown" inline />
              {{ membersRoles[user.role] }}
            </cell-simple>
            <div class="table-column dropdown">
              <pvp-btn
                v-if="user.role !== 1"
                class="dropdown-btn"
                variant="clear"
                icon-left="dots"
                @click="toggleMemberControls(user.hash)"
              />
              <div
                v-if="activeUserHash === user.hash"
                v-click-outside="
                  () => toggleMemberControls(user.hash)
                "
                class="members-control-popup"
              >
                <div
                  class="members-control"
                  @click="changeRole(user.hash)"
                >
                  <icon
                    :name="
                      members.isSpare ? 'arrow-up' : 'arrow-down'
                    "
                  />
                  {{
                    $t(
                      `teams.edit_members_${
                        members.isSpare ? 'toMain' : 'toSpare'
                      }`,
                    )
                  }}
                </div>
                <div
                  v-if="
                    user.role !== 1 &&
                    !editOnlyMembers &&
                    !members.isSpare
                  "
                  class="members-control"
                  @click="changeCaptain(user.hash)"
                >
                  <Icon name="crown" inline />
                  {{ $t('teams.edit_members_changeCaptain') }}
                </div>
                <div
                  class="members-control"
                  @click="deleteMember(user.hash)"
                >
                  <Icon name="cross" inline />
                  {{ $t('teams.edit_members_delete') }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <cell-simple
              class="empty"
              :value="$t('teams.edit_members_emptySlot')"
            />
            <cell-simple class="empty">&mdash;</cell-simple>
            <cell-simple class="empty">&mdash;</cell-simple>
            <cell-simple class="empty">&mdash;</cell-simple>
            <cell-simple class="empty" />
          </template>
        </div>
      </template>
    </div>
    <member-errors-modal
      v-model="isErrorModalOpen"
      :errors="changeRoleErrors"
    />
  </div>
</template>

<script>
import MemberErrorsModal from '@components/TeamComponents/MemberErrorsModal.vue';
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import {
  changeMemberRole,
  changeCaptainRole,
  deleteTeamMember,
} from '@src/shared/api/team';

export default {
  name: 'EditTeamMembers',
  components: {
    BaseUserLinkWithoutStore,
    MemberErrorsModal,
    CellHeader,
    CellSimple,
    Icon,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    changeRoleErrors: [],
    activeUserHash: null,

    isErrorModalOpen: false,
  }),
  computed: {
    ...mapGetters('teams', ['getTeam', 'getTeamMembers']),
    ...mapGetters('application', ['getGame', 'getGameByCode']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    hash() {
      return this.team?.hash;
    },

    membersRoles() {
      return {
        1: this.$t('teams.memberRoles_captain'),
        2: this.$t('teams.memberRoles_player'),
        3: this.$t('teams.memberRoles_spare'),
      };
    },

    membersList() {
      const teamMembers = this.team.players || [];
      const { main, spare } = teamMembers;

      main.length = this.team.numMembers;
      spare.length = this.team.numSpares;

      return [
        {
          listTitle: this.$t('teams.edit_members_mainRole'),
          list: main.map((user) => ({ ...user, name: user.tnNick })),
        },
        {
          listTitle: this.$t('teams.edit_members_spare'),
          isSpare: true,
          list: spare.map((user) => ({ ...user, name: user.tnNick })),
        },
      ];
    },

    gameNickTitle() {
      const game =
        this.getGame(this.team.idGame) || this.getGameByCode('oth');
      return `${this.$t('teams.edit_members_gameNick')} ${game.name}`;
    },

    editOnlyMembers() {
      return !this.team.canEditProfile && this.team.canEditRoster;
    },
  },
  watch: {
    changeRoleErrors() {
      if (this.changeRoleErrors.length) {
        this.showErrorModal();
      }
    },
  },
  methods: {
    ...mapActions('teams', ['changeCaptainRole']),

    toggleMemberControls(hash) {
      this.activeUserHash =
        this.activeUserHash === hash ? null : hash;
    },

    changeCaptain(userHash) {
      changeCaptainRole({
        teamHash: this.hash,
        userHash,
      })
        .then(({ errors, success }) => {
          if (success) {
            window.location.reload();
          }
          if (errors) {
            this.changeRoleErrors = errors;
          }
        })
        .finally(() => {
          this.toggleMemberControls(userHash);
        });
    },

    changeRole(userHash) {
      changeMemberRole({
        teamHash: this.hash,
        userHash,
      })
        .then((data) => {
          if (data?.success) {
            this.$emit('swap');
          }
        })
        .catch((err) => {
          this.changeRoleErrors = err.error;
        })
        .finally(() => {
          this.toggleMemberControls(userHash);
        });
    },

    deleteMember(userHash) {
      deleteTeamMember({
        teamHash: this.hash,
        userHash,
      }).finally(() => {
        this.$emit('deletePlayer');
        this.toggleMemberControls(userHash);
      });
    },

    showErrorModal() {
      this.isErrorModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table.adaptive {
  background-color: rgba(black, 0.15);

  .table-body {
    @include max-laptop() {
      padding-left: 12px;
      padding-right: 12px;
      .table-column {
        padding: 6px 0;
      }
    }
  }
}

.table {
  &-title {
    padding: 20px 0;
    font-weight: bold;
    font-size: 18px;
  }

  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-column {
    @include max-laptop() {
      &:first-of-type {
        position: absolute;

        ::v-deep .column-title {
          display: none;
        }
      }

      &.empty {
        ::v-deep .column-title {
          display: none;
        }

        & + .table-column {
          display: none;
        }
      }
    }

    & + & {
      @include min-laptop() {
        padding-left: 8px;
      }
      @include max-laptop() {
        margin-left: 28px;
        align-items: flex-start;

        ::v-deep .column-title {
          width: 100px;
          flex-shrink: 0;
          padding-right: 10px;
        }
      }
    }

    &.role {
      .icon {
        color: #e29413;
        margin-right: 8px;
      }
    }

    &.userlink {
      @include max-laptop() {
        margin-right: 20px;
      }
    }

    &.gamenick {
      overflow: hidden;

      .gamenick-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.dropdown {
      @include max-laptop() {
        position: absolute;
        right: 0;
        top: 12px;
      }

      .dropdown-btn {
        position: relative;
        font-size: 18px;
        color: rgba(#fff, 0.5);
        cursor: pointer;
      }
    }

    &.empty {
      color: rgba(white, 0.6);
    }
  }

  &-row {
    position: relative;
    @include min-laptop() {
      display: grid;
      grid-template-columns: 30px 2fr 1.6fr 1.2fr 1fr 20px;
    }
  }
}

.members-control-popup {
  position: absolute;
  right: 2px;
  padding: 10px 16px;
  border-radius: 6px;
  background-color: $charcoal-grey;
  z-index: 1;
  @include min-laptop() {
    top: 50px;
  }
  @include max-laptop() {
    top: 30px;
  }

  .members-control {
    display: flex;
    align-items: center;
    padding: 6px 0;
    font-size: 14px;
    cursor: pointer;
    color: rgba(white, 0.8);
    white-space: nowrap;

    &:first-of-type {
      border-bottom: 1px solid rgba(white, 0.1);
    }

    &:hover {
      color: rgba(white, 1);
    }

    .icon {
      margin-right: 8px;
    }
  }
}
</style>
