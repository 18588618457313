<template>
  <pvp-modal v-model="modalOpened" width="580">
    <div slot="modal-title">{{ $t('teams.delete_title') }}</div>
    <div class="delete-info-text">
      {{ $t('teams.delete_info', { name: team.name }) }}?
    </div>
    <div v-for="(error, key) in errors" :key="key" class="error">
      {{ error }}
    </div>
    <template slot="modal-footer">
      <pvp-btn
        class="delete-modal-btn"
        variant="secondary"
        @click="closeDeleteModal"
        >{{ $t('global.close') }}
      </pvp-btn>

      <pvp-btn
        class="delete-modal-btn"
        variant="primary"
        @click="onClick"
        >{{ $t('global.delete') }}
      </pvp-btn>
    </template>
  </pvp-modal>
</template>

<script>
export default {
  name: 'DeleteTeamModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    modalOpened: false,
    errors: [],
  }),
  watch: {
    modalOpened(value) {
      this.$emit('input', value);
    },
    value: {
      handler(value) {
        this.modalOpened = value;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('teams', ['deleteTeam']),
    closeDeleteModal() {
      this.modalOpened = false;
    },
    onClick() {
      this.errors = [];
      this.deleteTeam(this.team.hash)
        .then(() => {
          this.$emit('deleted');
        })
        .catch(({ error }) => {
          this.errors = error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-info-text {
  font-size: 16px;
  text-align: center;
}

.delete-modal-btn {
  width: 160px;

  & + .delete-modal-btn {
    margin-left: 12px;
  }

  @include max-tablet() {
    width: 100%;
    & + .delete-modal-btn {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

.error {
  color: $orangey-red;
  line-height: 1.1;
  font-size: 14px;
  margin-top: 1em;

  & + & {
    margin-top: 0.5em;
  }
}
</style>
