<template>
  <pvp-modal v-if="team" v-model="invitationModalOpened" width="500">
    <div slot="modal-title">{{ $t('teams.inviteToTeam_title') }}</div>

    <div v-if="loadingState.modal" class="loading-container">
      <div class="loading-text">{{ $t('global.loading') }}</div>
      <loader class="invitation-loader" />
    </div>

    <template v-else-if="hashExpired">
      <InfoBox variant="warning">
        {{ $t('teams.inviteToTeam_linkExpired') }}
      </InfoBox>
      <div slot="modal-footer" class="team-invitation-controls">
        <pvp-btn
          variant="secondary"
          @click="invitationModalOpened = false"
          >{{ $t('global.close') }}
        </pvp-btn>
      </div>
    </template>

    <template v-else>
      <div class="team-invitation-body">
        <template v-if="captainMember">
          <BaseUserLink
            :display-image="false"
            :hash="captainMember.hash"
          />
          {{ $t('teams.inviteToTeam_invitedByUser') }}
        </template>
        <template v-else>{{
          $t('teams.inviteToTeam_invited')
        }}</template>

        <div>
          <BaseTeamLink :display-image="false" :hash="team.hash" />
          {{ $t('teams.inviteToTeam_game') }} <b>{{ game.name }}</b>
        </div>

        <div class="members-accepted">
          <template v-if="teamMembers.length > 1">
            {{ $t('teams.inviteToTeam_membersAccepted') }}:
            {{ teamMembersNames }}
          </template>
          <template v-else>
            {{ $t('teams.inviteToTeam_firstMember') }}
          </template>
        </div>
      </div>

      <InfoBox v-if="success" variant="success">
        {{ $t('teams.inviteToTeam_userAccepted') }}
        <br />{{ $t('teams.inviteToTeam_timeHint') }}
      </InfoBox>

      <InfoBox v-if="errors.length" variant="error">
        <div v-for="(error, index) in errors" :key="index">
          {{ error }}
        </div>
      </InfoBox>

      <InfoBox v-if="!hasGameAccount">
        {{ $t('teams.inviteToTeam_gameAccWarning') }}
        <b>{{ game.name }}</b>
      </InfoBox>

      <template v-if="validationErrors.length || requiredFields">
        <ul class="validation-errors-list">
          <li v-if="requiredFields">
            {{ $t('teams.inviteToTeam_emptyFields') }}:
            <b>{{ requiredFields }}</b>
          </li>
          <!-- eslint-disable vue/no-v-html -->
          <li
            v-for="(error, index) in validationErrors"
            :key="index"
            v-html="error.error"
          ></li>
        </ul>
      </template>

      <div
        v-if="!success"
        slot="modal-footer"
        class="team-invitation-controls"
      >
        <template v-if="hasGameAccount">
          <router-link
            v-if="validationErrors.length || requiredFields"
            class="router-link"
            :to="{ name: 'profile-edit', hash: '#personal' }"
          >
            <pvp-btn>
              {{ $t('profile.edit_single') }}
            </pvp-btn>
          </router-link>
          <template v-else>
            <pvp-btn
              variant="secondary"
              :disabled="loadingState.invitationAccept"
              @click="declineTeamInvitation"
              >{{ $t('global.refuse') }}
            </pvp-btn>
            <pvp-btn
              :is-loading="loadingState.invitationAccept"
              :disabled="loadingState.invitationAccept"
              @click="acceptTeamInvitation"
              >{{ $t('global.accept') }}
            </pvp-btn>
          </template>
        </template>
        <game-account-linker v-else :id="team.idGame" />
      </div>
    </template>
  </pvp-modal>
</template>

<script>
import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TeamInviteModal',
  components: {
    GameAccountLinker,
    BaseUserLink,
    BaseTeamLink,
    InfoBox,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    invitationHash: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      modal: true,
      invitationAccept: false,
    },

    invitationModalOpened: false,

    hashExpired: false,

    // Errors
    errors: [],
    validationErrors: [],
    requiredFields: null,
    success: false,
  }),
  computed: {
    ...mapGetters('teams', ['getTeam', 'getTeamMembers']),
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    team() {
      return this.getTeam(this.hash);
    },

    game() {
      return this.getGame(this.team.idGame);
    },

    hasGameAccount() {
      return this.getLinkedGameAccount(this.team.idGame)?.hasAccount;
    },

    teamMembers() {
      return this.getTeamMembers(this.hash);
    },

    teamMembersNames() {
      return (this.teamMembers || [])
        .filter((member) => member.role !== 'captain')
        .map((member) => member.name)
        .join(', ');
    },

    captainMember() {
      return (this.teamMembers || []).find(
        (member) => member.role === 'captain',
      );
    },
  },
  watch: {
    invitationModalOpened(value) {
      this.$emit('input', value);

      if (!value) {
        this.redirectToTeamPage();
      }
    },
    value: {
      handler(value) {
        this.invitationModalOpened = value;
      },
      immediate: true,
    },
  },
  created() {
    this.fetchTeamInvitation(this.invitationHash)
      .then((team) => {
        if (team?.hash === this.hash) {
          this.invitationModalOpened = true;
        } else {
          this.redirectToTeamPage();
        }
      })
      .catch(({ code }) => {
        if (code === 401) {
          return false;
        }

        if (code === 404) {
          this.invitationModalOpened = true;
          this.hashExpired = true;
          return false;
        }

        return this.redirectToTeamPage();
      })
      .finally(() => {
        this.loadingState.modal = false;
      });
  },
  methods: {
    ...mapActions('teams', ['fetchTeamInvitation', 'fetchTeam']),
    acceptTeamInvitation() {
      const fieldNames = {
        mobile: this.$t('players.phone_single'),
        discord: 'Discord',
        gender: this.$t('players.gender_label'),
        firstName: this.$t('players.name_label'),
        lastName: this.$t('players.lastName_label'),
        middleName: this.$t('players.middleName_label'),
        idCountry: this.$t('players.country_label'),
        idRegion: this.$t('players.region_label'),
        city: this.$t('players.city_label'),
        birthdate: this.$t('players.birthdate_label'),
        nick: this.$t('players.nickname_label'),
        fksHash: this.$t('profile.fcs_account'),
      };

      this.errors = [];
      this.loadingState.invitationAccept = true;

      this.fetchTeam({
        hash: this.hash,
        complex: false,
      }).finally(() => {
        api
          .get(`/team/invitation/${this.invitationHash}/accept`)
          .then(() => {
            this.success = true;
            this.fetchTeam({
              hash: this.hash,
              complex: true,
            });
            setTimeout(this.redirectToTeamPage, 3000);
          })
          .catch(({ error = '' }) => {
            if (_.isArray(error)) {
              this.errors = error.reduce((res, { message, data }) => {
                if (data?.errors?.length) {
                  res = res.concat(data.errors);
                }
                if (message) {
                  res.push(message);
                }
                return res;
              }, []);

              const profileError = error.find(
                (err) => err.code === 'wrong_profile',
              );

              if (profileError) {
                let validationErrors = [];
                let requiredFields = [];

                profileError.reasons.forEach((item) => {
                  requiredFields = requiredFields.concat(item.empty);
                  validationErrors = validationErrors.concat(
                    item.errors,
                  );
                });

                this.validationErrors = validationErrors;
                this.requiredFields = _.uniq(requiredFields)
                  .map((field) => fieldNames[field])
                  .join(', ');
              }
            } else if (error.code === 404) {
              this.errors = [this.$t('teams.inviteToTeam_error404')];
            } else {
              this.errors = [this.$t('errors.unknown')];
            }
          })
          .finally(() => {
            this.loadingState.invitationAccept = false;
          });
      });
    },

    declineTeamInvitation() {
      this.redirectToTeamPage();
    },

    redirectToTeamPage() {
      this.$router
        .replace({
          name: 'team',
          params: { hash: this.hash },
        })
        .catch(() => ({}));
    },
  },
};
</script>

<style lang="scss" scoped>
.team-invitation-body {
  text-align: center;

  .members-accepted {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(white, 0.4);
  }

  .user-link,
  .team-link {
    font-weight: bold;
    color: dodgerblue;
  }
}

.team-invitation-body + .InfoBox {
  margin-top: 20px;
}

.team-invitation-controls {
  .button + .button {
    margin-left: 1rem;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading-text {
    font-size: 18px;
    font-weight: 600;
  }

  .invitation-loader {
    margin-top: 20px;
  }
}

.validation-errors-list {
  margin-top: 12px;
  font-size: 14px;
}

.router-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
</style>
