<template>
  <pvp-modal :value="value" width="580" @input="closeDeleteModal">
    <div slot="modal-title">{{ $t('global.error') }}</div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="info-text"
    >
      {{ error }}
    </div>
    <template slot="modal-footer">
      <pvp-btn
        class="error-modal-btn"
        variant="primary"
        @click="closeDeleteModal"
      >
        {{ $t('global.close') }}
      </pvp-btn>
    </template>
  </pvp-modal>
</template>

<script>
export default {
  name: 'MemberErrorsModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeDeleteModal() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-text {
  text-align: center;
}

.error-modal-btn {
  width: 160px;
}
</style>
